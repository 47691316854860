import { FaceTecSDK, FaceTecSDKStatus } from "facetec-sdk";
import { useContext, useMemo } from "react";
import { FaceTecSDKContext } from "./sdk/state";

type Status = {
  status: FaceTecSDKStatus;
  message: string;
};

const getStatus = (): Status => {
  const status = FaceTecSDK.getStatus();
  const message = FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(status);
  return { status, message };
};

type StateStatus = { tag: string } & Status;

const useFaceTecSDKStatus = (): StateStatus => {
  const sdkState = useContext(FaceTecSDKContext);

  const status = useMemo(() => {
    return { tag: sdkState.tag, ...getStatus() };
  }, [sdkState]);

  return status;
};

export default useFaceTecSDKStatus;
