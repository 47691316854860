import React, { useEffect, useState } from "react";
import { StateProps } from "../../lib/subsystem/taggedState";
import { State, ApiContext } from "./state";

type Props = {
  children: React.ReactNode;
  builder: () => Promise<StateProps<State>["ready"]>;
};

const ApiInit: React.FC<Props> = (props) => {
  const { children, builder } = props;

  const [value, setValue] = useState<State>({ tag: "pending", data: {} });
  useEffect(() => {
    builder()
      .then((data) => {
        setValue({ tag: "ready", data });
      })
      .catch((error) => {
        setValue({ tag: "error", data: { error } });
      });
  }, [builder]);

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export default ApiInit;
