import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ApiGuard, useApi } from "../../subsystem/api/state";
import ApiInit from "../../subsystem/api/ApiInit";
import { createApi, createProvider } from "../../subsystem/humanodePeerApi/api";
import Layout from "../Layout";
import ErrorPage from "../ErrorPage";
import { ChildrenProps } from "../../reactExt";

const ApiLoadingPage: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Connecting to the peer.</Typography>
  </Layout>
);

const ApiConnector: React.FC<ChildrenProps> = (props) => {
  const { children } = props;

  const builder = useCallback(async () => {
    const provider = await createProvider("ws://localhost:9944");
    const api = await createApi({ provider });
    return { api };
  }, []);

  const ready = useCallback(() => <>{children}</>, [children]);

  return (
    <ApiInit builder={builder}>
      <ApiGuard
        uninit={ApiLoadingPage}
        pending={ApiLoadingPage}
        ready={ready}
        error={ErrorPage}
      />
    </ApiInit>
  );
};

const Debug: React.FC = () => {
  const { api } = useApi();

  const [info, setInfo] = useState("");

  useEffect(() => {
    api.rpc.bioauth
      .getFacetecDeviceSdkParams()
      .then((params) => setInfo(JSON.stringify(params)));
  }, [api]);

  return (
    <Box sx={{ maxWidth: "100vw", wordWrap: "break-word" }}>
      <Typography>{info}</Typography>
    </Box>
  );
};

const ApiTestPage: React.FC = () => (
  <ApiConnector>
    <Debug />
  </ApiConnector>
);

export default ApiTestPage;
