import { ApiPromise } from "@polkadot/api";
import { FaceTecInitParams } from "../facetec/logic/faceTecInit";

export const getFacetecDeviceSdkParams = async (
  api: ApiPromise
): Promise<FaceTecInitParams> => {
  const { deviceKeyIdentifier, productionKey, publicFaceMapEncryptionKey } =
    await api.rpc.bioauth.getFacetecDeviceSdkParams();

  const params = {
    deviceKeyIdentifier: deviceKeyIdentifier.toString(),
    publicFaceMapEncryptionKey: publicFaceMapEncryptionKey.toString(),
  };

  if (productionKey.isNone) {
    return params;
  }

  return {
    ...params,
    productionKey: productionKey.unwrap().toString(),
  };
};

export const getFacetecSessionToken = async (
  api: ApiPromise
): Promise<string> => {
  const res = await api.rpc.bioauth.getFacetecSessionToken();
  return res.toString();
};

export const provideLivenessData = async (
  api: ApiPromise,
  livenessData: {
    faceScan: string;
    auditTrailImage: string;
    lowQualityAuditTrailImage: string;
  }
): Promise<void> => {
  await api.rpc.bioauth.provideLivenessData({
    face_scan: api.createType("Text", livenessData.faceScan),
    audit_trail_image: api.createType("Text", livenessData.auditTrailImage),
    low_quality_audit_trail_image: api.createType(
      "Text",
      livenessData.lowQualityAuditTrailImage
    ),
  });
};

export const enroll = async (
  api: ApiPromise,
  livenessData: {
    faceScan: string;
    auditTrailImage: string;
    lowQualityAuditTrailImage: string;
  }
): Promise<void> => {
  await api.rpc.bioauth.enroll({
    face_scan: api.createType("Text", livenessData.faceScan),
    audit_trail_image: api.createType("Text", livenessData.auditTrailImage),
    low_quality_audit_trail_image: api.createType(
      "Text",
      livenessData.lowQualityAuditTrailImage
    ),
  });
};

export const authenticate = async (
  api: ApiPromise,
  livenessData: {
    faceScan: string;
    auditTrailImage: string;
    lowQualityAuditTrailImage: string;
  }
): Promise<void> => {
  await api.rpc.bioauth.authenticate({
    face_scan: api.createType("Text", livenessData.faceScan),
    audit_trail_image: api.createType("Text", livenessData.auditTrailImage),
    low_quality_audit_trail_image: api.createType(
      "Text",
      livenessData.lowQualityAuditTrailImage
    ),
  });
};
