import { ApiPromise } from "@polkadot/api";
import { StateVariant } from "../../lib/subsystem/taggedState";
import create from "../../lib/subsystem/react/create";
import createTaggedStateHook from "../../lib/subsystem/react/createTaggedStateHook";
import createExpectContextState from "../../lib/subsystem/react/createExpectContextState";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<"ready", { api: ApiPromise }>
  | StateVariant<"error", { error: Error }>;

const { Context, Guard } = create<State>({
  tag: "uninit",
  data: {},
});

export const ApiContext = Context;
export const ApiGuard = Guard;

export const RequireApi = createExpectContextState(Context, "ready" as const);
export const useApi = createTaggedStateHook(Context, "ready" as const);
