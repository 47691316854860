import create from "../../../lib/subsystem/react/create";
import { StateVariant } from "../../../lib/subsystem/taggedState";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<"ready">
  | StateVariant<"failed">
  | StateVariant<"error", { error: Error }>;

const { Context, Guard } = create<State>({ tag: "uninit", data: {} });

export const FaceTecSDKContext = Context;
export const FaceTecSDKGuard = Guard;
