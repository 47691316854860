import create from "../../../lib/subsystem/react/create";
import createTaggedStateHook from "../../../lib/subsystem/react/createTaggedStateHook";
import { StateVariant } from "../../../lib/subsystem/taggedState";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<"ready", { sessionToken: string }>
  | StateVariant<"error", { error: Error }>;

const { Context, Guard } = create<State>({
  tag: "uninit",
  data: {},
});

export const FaceTecSessionTokenContext = Context;
export const FaceTecSessionTokenGuard = Guard;

export const useFaceTecSessionToken = createTaggedStateHook(
  Context,
  "ready" as const
);
