import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { Typography } from "@mui/material";

type Props = {
  error: Error;
};

const ErrorDetails: React.FC<Props> = (props) => {
  const { error } = props;
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="button">Error details</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "block" }}>
        <Typography variant="body1" sx={{ fontFamily: "monospace" }}>
          {error.name}: {error.message}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default ErrorDetails;
