import React, { useCallback, useEffect, useMemo } from "react";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import useRequiredParams from "../hooks/useRequiredParams";
import ApiTestPage from "../pages/dev/ApiTestPage";
import FaceTecTestPage from "../pages/dev/FaceTecTestPage";
import RenderErrorPage from "../pages/dev/RenderErrorPage";
import LoadingPage from "../pages/LoadingPage";
import MainPage from "../pages/MainPage";
import NodeConnectionParamsEntryPage from "../pages/NodeConnectionParamsEntryPage";
import {
  NodeConnectionParams,
  NodeConnectionParamsContext,
} from "../structure/NodeConnectionParams";

const makeConnectUrl = (url: string) => `/humanode/${encodeURIComponent(url)}`;

const OpenRoute: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const url = searchParams.get("url");

  useEffect(() => {
    if (!url) {
      return;
    }
    navigate(makeConnectUrl(url), { replace: true });
  }, [navigate, url]);

  return <LoadingPage />;
};

const NodeConnectionParamsEntryRoute: React.FC = () => {
  const navigate = useNavigate();

  const connect = useCallback(
    (url: string) => {
      navigate(makeConnectUrl(url));
    },
    [navigate]
  );

  return <NodeConnectionParamsEntryPage onConnect={connect} />;
};

const MainPageRoute: React.FC = () => {
  const { url } = useRequiredParams("url");

  const nodeConnectionParams = useMemo<NodeConnectionParams>(
    () => ({ url }),
    [url]
  );

  return (
    <NodeConnectionParamsContext.Provider value={nodeConnectionParams}>
      <MainPage />
    </NodeConnectionParamsContext.Provider>
  );
};

const Root: React.FC = () => (
  <Routes>
    <Route path="/" element={<NodeConnectionParamsEntryRoute />} />
    <Route path="/open" element={<OpenRoute />} />
    <Route path="/humanode/:url/*" element={<MainPageRoute />} />
    <Route path="/dev">
      <Route path="api-test" element={<ApiTestPage />} />
      <Route path="render-error" element={<RenderErrorPage />} />
      <Route path="facetec-test" element={<FaceTecTestPage />} />
    </Route>
    <Route path="*" element={<LoadingPage />} />
  </Routes>
);

export default Root;
