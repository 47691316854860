import React, { useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import { Stack } from "@mui/material";
import useTheme from "@mui/system/useTheme";
import useAsyncCallback from "../../hooks/useAsyncCallback";
import useThrow from "../../hooks/useThrow";
import faceTecInit, {
  FaceTecInitParams,
} from "../../subsystem/facetec/logic/faceTecInit";
import FaceTecSDKInit from "../../subsystem/facetec/sdk/FaceTecSDKInit";
import faceTecUnload from "../../subsystem/facetec/logic/faceTecUnload";
import useFaceTecSDKStatus from "../../subsystem/facetec/useFaceTecSDKStatus";
import Layout from "../Layout";

const Debug: React.FC = () => {
  const status = useFaceTecSDKStatus();
  return (
    <>
      <div>Status:</div>
      <div>{JSON.stringify(status)}</div>
    </>
  );
};

const FaceTecTestPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const initParams = JSON.parse(
    searchParams.get("initParams") || "{}"
  ) as FaceTecInitParams;

  const throwError = useThrow();
  const theme = useTheme();

  const facetecInitRoutine = useAsyncCallback(
    async () => {
      return await faceTecInit({ ...initParams }, theme);
    },
    throwError,
    [theme]
  );

  const facetecUnloadRoutine = useAsyncCallback(
    async () => {
      return await faceTecUnload();
    },
    throwError,
    []
  );

  const initParamsRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

  const handleSettingsSubmit = useCallback(
    (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        const initParams = JSON.parse(initParamsRef.current!.value);
        searchParams.set("initParams", JSON.stringify(initParams));
        // Here `as Location` is needed to specify the `location` in this
        // context is a `Location`, and not a `WorkerLocation` - which is a type
        // that has `search` marked as `readonly`.
        // The reason why it's needed in the first palce is that in tests,
        // `ts-jest` loads in the `webworker` environment as part of
        // service-worker script, which makes the `window.location` type
        // effectively `Location & WorkerLocation`.
        // This only happens in tests though, and the proper fix would be to
        // turn make `ts-jest` process files separately, and avoid loading
        // the service worker code (and it's types) when testing this file -
        // which is unrelated to service worker; this is non-trivial though,
        // while this workaround is easy.
        // TODO: implement a proper fix.
        (window.location as Location).search = searchParams.toString();
      } catch (error) {
        console.error(error);
      }
    },
    [searchParams]
  );

  return (
    <Layout>
      <Box width="100%">
        <form onSubmit={handleSettingsSubmit}>
          <Stack gap={1}>
            <div>FaceTec SDK init params:</div>
            <TextField
              multiline
              fullWidth
              inputRef={initParamsRef}
              defaultValue={JSON.stringify(initParams, undefined, "  ")}
            />
            <Button type="submit">Apply</Button>
          </Stack>
        </form>
      </Box>
      <FaceTecSDKInit
        faceTecInit={facetecInitRoutine}
        faceTecUnload={facetecUnloadRoutine}
      >
        <Debug />
      </FaceTecSDKInit>
    </Layout>
  );
};

export default FaceTecTestPage;
