import {
  FaceTecFaceScanProcessor,
  FaceTecSDK,
  FaceTecSession,
} from "facetec-sdk";
import { useCallback, useMemo, useState } from "react";

export type StartCapture = () => FaceTecSession;

const useStartCapture = (
  processor: FaceTecFaceScanProcessor,
  sessionToken: string
): [StartCapture, boolean] => {
  const [faceTecSession, setFaceTecSession] = useState<FaceTecSession | null>(
    null
  );

  const handleDone = useCallback(() => {
    setFaceTecSession(null);
    processor.onFaceTecSDKCompletelyDone();
  }, [processor]);

  const wrappedProcessor: FaceTecFaceScanProcessor = useMemo(() => {
    return {
      onFaceTecSDKCompletelyDone: handleDone,
      processSessionResultWhileFaceTecSDKWaits:
        processor.processSessionResultWhileFaceTecSDKWaits,
    };
  }, [handleDone, processor.processSessionResultWhileFaceTecSDKWaits]);

  const startCapture = useCallback(() => {
    // Do not start the capture process second time until the first one is done.
    if (faceTecSession) return faceTecSession;

    // Creating a new session immediately displays the capture UI.
    const newSession = new FaceTecSDK.FaceTecSession(
      wrappedProcessor,
      sessionToken
    );

    // Lock the double-entry gate above.
    setFaceTecSession(newSession);

    return newSession;
  }, [sessionToken, faceTecSession, wrappedProcessor]);

  return [startCapture, !!faceTecSession];
};

export default useStartCapture;
