import { ApiPromise, HttpProvider, WsProvider } from "@polkadot/api";
import { ApiOptions, AugmentedRpc } from "@polkadot/api/types";
import { Option, Null, Text } from "@polkadot/types";
import { ProviderInterface } from "@polkadot/rpc-provider/types";
import { Observable } from "@polkadot/types/types";

export type FacetecDeviceSdkParams = {
  deviceKeyIdentifier: Text;
  publicFaceMapEncryptionKey: Text;
  productionKey: Option<Text>;
};

export type LivenessData = {
  face_scan: Text;
  audit_trail_image: Text;
  low_quality_audit_trail_image: Text;
};

declare module "@polkadot/rpc-core/types" {
  interface RpcInterface {
    bioauth: {
      getFacetecDeviceSdkParams: AugmentedRpc<
        () => Observable<FacetecDeviceSdkParams>
      >;
      getFacetecSessionToken: AugmentedRpc<() => Observable<Text>>;
      provideLivenessData: AugmentedRpc<
        (livenessData: LivenessData) => Observable<Null>
      >;
      enroll: AugmentedRpc<(livenessData: LivenessData) => Observable<Null>>;
      authenticate: AugmentedRpc<
        (livenessData: LivenessData) => Observable<Null>
      >;
    };
  }
}

export const createProvider = async (
  url: string
): Promise<ProviderInterface> => {
  if (url.startsWith("ws://") || url.startsWith("wss://")) {
    return new WsProvider(url);
  }
  return new HttpProvider(url);
};

export const createApi = (
  options: Pick<ApiOptions, "provider" | "throwOnConnect">
): Promise<ApiPromise> =>
  ApiPromise.create({
    ...options,
    types: {
      FacetecDeviceSdkParams: {
        deviceKeyIdentifier: "Text",
        publicFaceMapEncryptionKey: "Text",
        productionKey: "Option<Text>",
      },
      LivenessData: {
        face_scan: "Text",
        audit_trail_image: "Text",
        low_quality_audit_trail_image: "Text",
      },
    },
    rpc: {
      bioauth: {
        getFacetecDeviceSdkParams: {
          description: "Get FaceTec device sdk params",
          params: [],
          type: "FacetecDeviceSdkParams",
        },
        provideLivenessData: {
          description: "Provide the liveness data to the node",
          params: [{ name: "liveness_data", type: "LivenessData" }],
          type: "Null",
        },
        enroll: {
          description: "Enroll with the provided liveness data",
          params: [{ name: "liveness_data", type: "LivenessData" }],
          type: "Null",
        },
        authenticate: {
          description: "Authenticate with the provided liveness data",
          params: [{ name: "liveness_data", type: "LivenessData" }],
          type: "Null",
        },
        getFacetecSessionToken: {
          description: "Get FaceTec session token",
          params: [],
          type: "Text",
        },
      },
    },
  });
