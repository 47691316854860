import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useTheme from "@mui/system/useTheme";
import { ApiPromise } from "@polkadot/api";
import { Route, Routes } from "react-router";
import { Link } from "react-router-dom";
import { ApiGuard, useApi } from "../subsystem/api/state";
import ApiInit from "../subsystem/api/ApiInit";
import { FaceTecSDKGuard } from "../subsystem/facetec/sdk/state";
import FaceTecSDKInit from "../subsystem/facetec/sdk/FaceTecSDKInit";
import { NodeConnectionParamsContext } from "../structure/NodeConnectionParams";
import ServiceWorkerGuard from "../components/ServiceWorkerGuard";
import { addAppError } from "../state/appErrors";
import faceTecInit from "../subsystem/facetec/logic/faceTecInit";
import { createApi, createProvider } from "../subsystem/humanodePeerApi/api";
import {
  getFacetecDeviceSdkParams,
  getFacetecSessionToken,
  provideLivenessData,
  enroll,
  authenticate,
} from "../subsystem/humanodePeerApi/wrappers";
import useAsyncCallback from "../hooks/useAsyncCallback";
import faceTecUnload from "../subsystem/facetec/logic/faceTecUnload";
import FaceTecSessionInit from "../subsystem/facetec/sessionToken/FaceTecSessionTokenInit";
import {
  FaceTecSessionTokenGuard,
  useFaceTecSessionToken,
} from "../subsystem/facetec/sessionToken/state";
import useStartCapture from "../subsystem/facetec/useStartCapture";
import makeProcessor, {
  Effect,
  LivenessData,
} from "../subsystem/facetec/makeProcessor";
import {
  isRpcErrorInterface,
  isShouldRetry,
} from "../subsystem/humanodePeerApi/typeGuards";
import { ChildrenProps } from "../reactExt";
import Layout from "./Layout";
import { useBoundErrorPage } from "./ErrorPage";

const ServiceWorkerLoadingPage: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Waiting for the service worker...</Typography>
    <Typography variant="body2" textAlign="center">
      If it takes too long, you might be using an incompatible web browser.
      <br />
      Try using Google Chrome, it is known to work on all platforms.
    </Typography>
  </Layout>
);

const ApiLoadingPage: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Connecting to the peer...</Typography>
  </Layout>
);

const FaceTecSDKLoadingPage: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Waiting for the FaceTec SDK...</Typography>
  </Layout>
);

const FaceTecSessionLoadingPage: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography>Waiting for the FaceTec session...</Typography>
  </Layout>
);

const RetryButton: React.FC = () => {
  const handleRetry = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Button
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      onClick={handleRetry}
    >
      Retry
    </Button>
  );
};

type CaptureAgainButtonProps = {
  captureAgain: () => void;
};

const CaptureAgainButton: React.FC<CaptureAgainButtonProps> = (props) => {
  const { captureAgain } = props;
  return (
    <Button variant="outlined" onClick={captureAgain}>
      Capture again
    </Button>
  );
};

const BackToDashboardButton: React.FC = () => {
  return (
    <Button variant="text" component={Link} to="..">
      Back to dashboard
    </Button>
  );
};

const useExtendedError = (
  explainer: React.ReactNode,
  details?: React.ReactNode,
  backButton?: boolean
): React.FC<{ error: Error }> => {
  const extension = (
    <>
      <Typography variant="h5" align="center" component="span">
        {explainer}
      </Typography>
      {details && (
        <Typography variant="body2" component="span">
          {details}
        </Typography>
      )}
      <RetryButton />
      {backButton && <BackToDashboardButton />}
    </>
  );
  return useBoundErrorPage(extension);
};

const ApiConnector: React.FC<ChildrenProps> = (props) => {
  const nodeConnectionParams = useContext(NodeConnectionParamsContext);

  if (nodeConnectionParams === null) {
    throw new Error("nodeConnectionParams is null");
  }

  const { children } = props;

  const builder = useAsyncCallback(
    async () => {
      const provider = await createProvider(nodeConnectionParams.url);
      const api = await createApi({ provider, throwOnConnect: true });
      return { api };
    },
    addAppError,
    [nodeConnectionParams]
  );

  const ready = useCallback(() => <>{children}</>, [children]);

  const error = useExtendedError(
    <>The connection to the peer could not be established.</>,
    <>
      This might be caused by:
      <ul>
        <li>a misbehaving ngrok at the peer end,</li>
        <li>or no internet connectivity at the peer system or this device,</li>
        <li>
          or maybe the peer is not currently running to accept a connection,
        </li>
        <li>
          or peer was unable to bind to the expected port (can happen if peer is
          launched on a system that is shared together with other peers instead
          of a dedicated system).
        </li>
      </ul>
    </>
  );

  return (
    <ApiInit builder={builder}>
      <ApiGuard
        uninit={ApiLoadingPage}
        pending={ApiLoadingPage}
        ready={ready}
        error={error}
      />
    </ApiInit>
  );
};

const FaceTecSDKInitializer: React.FC<ChildrenProps> = (props) => {
  const { children } = props;

  const { api } = useApi();
  const theme = useTheme();

  const facetecInitRoutine = useAsyncCallback(
    async () => {
      const params = await getFacetecDeviceSdkParams(api);
      return await faceTecInit({ ...params }, theme);
    },
    addAppError,
    [api, theme]
  );

  const facetecUnloadRoutine = useAsyncCallback(
    async () => {
      return await faceTecUnload();
    },
    addAppError,
    [api]
  );

  return (
    <FaceTecSDKInit
      faceTecInit={facetecInitRoutine}
      faceTecUnload={facetecUnloadRoutine}
    >
      {children}
    </FaceTecSDKInit>
  );
};

const makeBoundFaceTecSDKFailedPage = (backButton: boolean): React.FC => {
  const BoundFaceTecSDKFailedPage: React.FC = () => {
    const ErrorComponent = useExtendedError(
      <>FaceTec SDK failed to initialize.</>,
      <>
        We are unable to proceed with the bioauth flow because the FaceTec SDK
        refused to operate.
      </>,
      backButton
    );
    const error = useMemo(() => new Error("FaceTec SDK failed to init"), []);
    return <ErrorComponent error={error} />;
  };
  return BoundFaceTecSDKFailedPage;
};

const makeBoundFaceTecSDKGuard = (
  backButton: boolean
): React.FC<ChildrenProps> => {
  const BoundFaceTecSDKFailedPage = makeBoundFaceTecSDKFailedPage(backButton);

  const BoundFaceTecSDKGuard: React.FC<ChildrenProps> = (props) => {
    const { children } = props;
    const ready = useCallback(() => <>{children}</>, [children]);
    const error = useExtendedError(
      <>Error while initializing FaceTec SDK.</>,
      undefined,
      backButton
    );

    return (
      <FaceTecSDKGuard
        uninit={FaceTecSDKLoadingPage}
        pending={FaceTecSDKLoadingPage}
        ready={ready}
        failed={BoundFaceTecSDKFailedPage}
        error={error}
      />
    );
  };

  return BoundFaceTecSDKGuard;
};

const LegacyFaceTecSDKGuard: React.FC<ChildrenProps> =
  makeBoundFaceTecSDKGuard(false);
const NewFaceTecSDKGuard: React.FC<ChildrenProps> =
  makeBoundFaceTecSDKGuard(true);

const FaceTecSessionInitializer: React.FC<ChildrenProps> = (props) => {
  const { children } = props;

  const { api } = useApi();

  const boundGetSessionToken = useAsyncCallback(
    () => {
      return getFacetecSessionToken(api).then((sessionToken) => ({
        sessionToken,
      }));
    },
    addAppError,
    [api]
  );

  const ready = useCallback(() => <>{children}</>, [children]);

  const error = useExtendedError(
    <>Error while establishing FaceTec session.</>
  );

  return (
    <FaceTecSessionInit getSessionToken={boundGetSessionToken}>
      <FaceTecSessionTokenGuard
        uninit={FaceTecSessionLoadingPage}
        pending={FaceTecSessionLoadingPage}
        ready={ready}
        error={error}
      />
    </FaceTecSessionInit>
  );
};

type FaceTecCaptureProps = {
  sessionToken: string;
  onLivenessData: (livenessData: LivenessData) => Promise<Effect>;
  onDone: () => void;
  onError: (error: Error) => void;
};

const FaceTecCapture: React.FC<FaceTecCaptureProps> = (props) => {
  const { onDone, onError, onLivenessData, sessionToken } = props;

  const processor = useMemo(() => {
    return makeProcessor({
      onDone,
      handleLivenessData: onLivenessData,
      onError,
    });
  }, [onDone, onError, onLivenessData]);

  const [startCapture, facetecCaptureInProgress] = useStartCapture(
    processor,
    sessionToken
  );

  const autostartTriggeredRef = useRef(false);
  useEffect(() => {
    if (!autostartTriggeredRef.current && !facetecCaptureInProgress) {
      autostartTriggeredRef.current = true;
      startCapture();
    }
  }, [facetecCaptureInProgress, startCapture]);

  return null;
};

const useCaptureFlow = (
  onLivenessData: (
    api: ApiPromise,
    livenessData: LivenessData
  ) => Promise<Effect>
) => {
  const [captureDone, setCaptureDone] = useState(false);
  const [captureError, setCaptureError] = useState<Error | null>(null);

  const { api } = useApi();
  const { sessionToken } = useFaceTecSessionToken();

  const handleLivenessData = useCallback(
    (livenessData: LivenessData) => onLivenessData(api, livenessData),
    [api, onLivenessData]
  );

  const handleDone = useCallback(() => {
    setCaptureDone(true);
  }, []);
  const handleError = useCallback((error: Error) => {
    setCaptureError(error);
  }, []);
  const handleCaptureAgain = useCallback(() => {
    setCaptureDone(false);
    setCaptureError(null);
  }, []);

  return {
    handleDone,
    handleCaptureAgain,
    handleError,
    handleLivenessData,
    sessionToken,
    captureDone,
    captureError,
  };
};

type LegacyCaptureFlowProps = {
  onLivenessData: (
    api: ApiPromise,
    livenessData: LivenessData
  ) => Promise<Effect>;
};

const LegacyCaptureFlow: React.FC<LegacyCaptureFlowProps> = (props) => {
  const { onLivenessData } = props;
  const {
    handleDone,
    handleCaptureAgain,
    handleError,
    handleLivenessData,
    sessionToken,
    captureDone,
    captureError,
  } = useCaptureFlow(onLivenessData);

  return captureDone ? (
    captureError ? (
      <Layout logo>
        <Typography variant="h4" align="center">
          Capture failed.
        </Typography>
        <Typography align="center">{captureError.message}</Typography>
        <CaptureAgainButton captureAgain={handleCaptureAgain} />
      </Layout>
    ) : (
      <Layout logo>
        <Typography variant="h4" align="center">
          Capture complete!
        </Typography>
        <Typography align="center">
          Check the terminal to see the results.
        </Typography>
        <CaptureAgainButton captureAgain={handleCaptureAgain} />
      </Layout>
    )
  ) : (
    <Layout logo>
      <FaceTecCapture
        onDone={handleDone}
        onError={handleError}
        sessionToken={sessionToken}
        onLivenessData={handleLivenessData}
      />
      <CircularProgress />
      <Typography>FaceTec SDK is capturing biometrics...</Typography>
    </Layout>
  );
};

type NewCaptureFlowProps = {
  onLivenessData: (
    api: ApiPromise,
    livenessData: LivenessData
  ) => Promise<Effect>;
  failureHeader: string;
  successHeader: string;
  captureHeader: string;
};

const NewCaptureFlow: React.FC<NewCaptureFlowProps> = (props) => {
  const { onLivenessData, captureHeader, failureHeader, successHeader } = props;
  const {
    handleDone,
    handleCaptureAgain,
    handleError,
    handleLivenessData,
    sessionToken,
    captureDone,
    captureError,
  } = useCaptureFlow(onLivenessData);

  return captureDone ? (
    captureError ? (
      <Layout logo>
        <Container maxWidth="xs">
          <Stack spacing={2} textAlign="center" alignItems="center">
            <Typography variant="h4" align="center">
              {failureHeader}
            </Typography>
            <Typography align="center">{captureError.message}</Typography>
            <CaptureAgainButton captureAgain={handleCaptureAgain} />
            <BackToDashboardButton />
          </Stack>
        </Container>
      </Layout>
    ) : (
      <Layout logo>
        <Container maxWidth="xs">
          <Stack spacing={2} textAlign="center" alignItems="center">
            <Typography variant="h4" align="center">
              {successHeader}
            </Typography>
            <BackToDashboardButton />
          </Stack>
        </Container>
      </Layout>
    )
  ) : (
    <Layout logo>
      <Container maxWidth="xs">
        <Stack spacing={2} textAlign="center" alignItems="center">
          <Typography variant="h4" align="center">
            {captureHeader}
          </Typography>
          <FaceTecCapture
            onDone={handleDone}
            onError={handleError}
            sessionToken={sessionToken}
            onLivenessData={handleLivenessData}
          />
          <CircularProgress />
          <Typography align="center">
            FaceTec SDK is capturing biometrics...
          </Typography>
          <Button variant="text" component={Link} to="..">
            Back to dashboard
          </Button>
        </Stack>
      </Container>
    </Layout>
  );
};

const provideLivenessDataEffect = async (
  api: ApiPromise,
  livenessData: LivenessData
): Promise<Effect> => {
  await provideLivenessData(api, livenessData);
  return "success";
};

const tryBioauthCall = async (call: () => Promise<void>): Promise<Effect> => {
  try {
    await call();
    return "success";
  } catch (error: unknown) {
    if (isRpcErrorInterface(error)) {
      const { data } = error;
      if (isShouldRetry(data)) {
        console.log("Retrying face scan for caught error: ", error);
        return "retry";
      }
    }
    throw error;
  }
};

const enrollEffect = async (
  api: ApiPromise,
  livenessData: LivenessData
): Promise<Effect> => tryBioauthCall(() => enroll(api, livenessData));

const authenticateEffect = async (
  api: ApiPromise,
  livenessData: LivenessData
): Promise<Effect> => tryBioauthCall(() => authenticate(api, livenessData));

const CommonBoundary: React.FC<ChildrenProps> = (props) => {
  const { children } = props;
  return (
    <ServiceWorkerGuard notReady={<ServiceWorkerLoadingPage />}>
      <ApiConnector>{children}</ApiConnector>
    </ServiceWorkerGuard>
  );
};

const LegacyFlowPage: React.FC = () => (
  <FaceTecSDKInitializer>
    <LegacyFaceTecSDKGuard>
      <FaceTecSessionInitializer>
        <LegacyCaptureFlow onLivenessData={provideLivenessDataEffect} />
      </FaceTecSessionInitializer>
    </LegacyFaceTecSDKGuard>
  </FaceTecSDKInitializer>
);

const NewCaptureFlowBase: React.FC<ChildrenProps> = (props) => {
  const { children } = props;
  return (
    <FaceTecSDKInitializer>
      <NewFaceTecSDKGuard>
        <FaceTecSessionInitializer>{children}</FaceTecSessionInitializer>
      </NewFaceTecSDKGuard>
    </FaceTecSDKInitializer>
  );
};
const AuthenticatePage: React.FC = () => (
  <NewCaptureFlowBase>
    <NewCaptureFlow
      onLivenessData={authenticateEffect}
      captureHeader="Authenticating..."
      successHeader="Authentication complete!"
      failureHeader="Authentication failed."
    />
  </NewCaptureFlowBase>
);

const EnrollPage: React.FC = () => (
  <NewCaptureFlowBase>
    <NewCaptureFlow
      onLivenessData={enrollEffect}
      captureHeader="Enrolling..."
      successHeader="Enrollment complete!"
      failureHeader="Enrollment failed."
    />
  </NewCaptureFlowBase>
);

const EnrollWarningPage: React.FC = () => (
  <Layout logo>
    <Container maxWidth="sm">
      <Stack spacing={2}>
        <Alert severity="warning">As a human, you can only enroll once!</Alert>
        <Stack spacing={1}>
          <Typography variant="overline">
            You naturally only have a single biometric identity.
          </Typography>
          <Typography variant="overline">
            Humanode Network is designed to ensure one human can't run more than
            one node.
          </Typography>
          <Typography variant="overline">
            You can only link one private key to your biometric identity.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="caption">
            Make sure you can recover you private key in case you loose access
            to this instance of the node by saving the mnemonic phrase for your
            private key.
          </Typography>
          <Typography variant="caption">
            If you lose the private key after you link it to your biometric
            identity, you will loose access to the network and will be unable to
            participate in the Humanode Network.
          </Typography>
        </Stack>
        <Button
          size="large"
          variant="contained"
          component={Link}
          to="../enroll"
        >
          Yes, I understand
        </Button>
        <Button size="large" component={Link} to="..">
          Cancel
        </Button>
      </Stack>
    </Container>
  </Layout>
);

const DashboardPage: React.FC = () => (
  <Layout logo>
    <Container maxWidth="xs">
      <Stack spacing={4} textAlign="center" alignItems="center">
        <Stack spacing={1}>
          <Button
            size="large"
            variant="contained"
            color="success"
            component={Link}
            to="authenticate"
          >
            Authenticate
          </Button>
          <Typography variant="caption">
            Authenticate the node to the Humanode Network using your biometric
            information to start participating in consensus.
          </Typography>
          <Typography variant="caption">
            You must be enrolled before you can authenticate.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Button
            size="large"
            variant="outlined"
            component={Link}
            to="enroll-warning"
          >
            Enroll
          </Button>
          <Typography variant="caption">
            Link your biometric information to the node, permanently connecting
            the node's private key to your biometric. Enrolment is done only
            once when you launch the node for the first time.
          </Typography>
        </Stack>
      </Stack>
    </Container>
  </Layout>
);

const NewFlowPage: React.FC = () => {
  return (
    <Routes>
      <Route index element={<DashboardPage />}></Route>
      <Route path="authenticate" element={<AuthenticatePage />}></Route>
      <Route path="enroll-warning" element={<EnrollWarningPage />}></Route>
      <Route path="enroll" element={<EnrollPage />}></Route>
    </Routes>
  );
};

const hasNewFlowRpcs = (api: ApiPromise): boolean =>
  api.rpc.bioauth.authenticate !== undefined &&
  api.rpc.bioauth.enroll !== undefined;

const FlowPage: React.FC = () => {
  const { api } = useApi();
  const useNewFlow = useMemo(() => hasNewFlowRpcs(api), [api]);
  return useNewFlow ? <NewFlowPage /> : <LegacyFlowPage />;
};

const MainPage: React.FC = () => (
  <CommonBoundary>
    <FlowPage />
  </CommonBoundary>
);

export default MainPage;
