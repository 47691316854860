import { DependencyList, useCallback } from "react";

export type OnError = (error: Error) => void;

// Take an async callback, and wrap in with a proper error handler.
const useAsyncCallback = <T extends () => Promise<any>>(
  callback: T,
  onError: OnError,
  deps: DependencyList
): T => {
  const inner = useCallback(async (): Promise<any> => {
    try {
      return await callback();
    } catch (error) {
      onError(error as Error);
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onError, ...deps]) as T;
  return inner;
};

export default useAsyncCallback;
