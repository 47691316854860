import React from "react";

type Props = {
  notReady: React.ReactNode;
  children: React.ReactNode;
};

type State = {
  serviceWorkerReady: boolean;
};

class ServiceWorkerGuard extends React.Component<Props, State> {
  state = {
    serviceWorkerReady: false,
  };

  onServiceWorkerMessage = (event: MessageEvent) => {
    if (!event.data) return;

    if (event.data.type === "SERVICE_WORKER_READY") {
      console.log("The service worker has become ready");

      this.setState({
        serviceWorkerReady: true,
      });
    }
  };

  componentDidMount() {
    if (process.env.NODE_ENV === "development") {
      const skip = "#swguard";
      if (window.location.hash === skip) {
        console.log(
          "We're in dev mode, and service worker guard skipping is disabled"
        );
      } else {
        console.log(
          `We're in dev mode, and about to skip the service worker guard; disable by adding ${skip} to the URL`
        );
        this.setState({
          serviceWorkerReady: true,
        });
        return;
      }
    }

    const { serviceWorker } = window.navigator;

    if (!serviceWorker) {
      console.log("The service worker in unavailable");
      throw new Error("service worker unsupported");
    }

    serviceWorker.onmessage = this.onServiceWorkerMessage;

    console.log("Awaiting for the service worker to be ready");
    serviceWorker.ready
      .then((registration) => {
        if (!registration.active) {
          throw new Error("The service worker is not active");
        }

        registration.active.postMessage({
          type: "SERVICE_WORKER_HEALTH_CHECK",
        });
      })
      .catch((error: Error) => {
        console.log(
          "An error while awaiting for the service worker to become ready",
          error
        );
        throw error;
      });
  }

  render() {
    const { serviceWorkerReady } = this.state;
    if (serviceWorkerReady) {
      return this.props.children;
    } else {
      return this.props.notReady;
    }
  }
}

export default ServiceWorkerGuard;
