import { FaceTecFaceScanProcessor, FaceTecSDK } from "facetec-sdk";

export type Effect = "retry" | "success";

export type LivenessData = {
  faceScan: string;
  auditTrailImage: string;
  lowQualityAuditTrailImage: string;
};

type MakeProcessorProps = {
  handleLivenessData: (livenessData: LivenessData) => Promise<Effect>;
  onDone: () => void;
  onError: (error: Error) => void;
};

const makeProcessor = (props: MakeProcessorProps): FaceTecFaceScanProcessor => {
  const { handleLivenessData, onDone, onError } = props;
  const handleError = (error: Error) => {
    console.log(error);
    onError(error);
  };
  const process: FaceTecFaceScanProcessor["processSessionResultWhileFaceTecSDKWaits"] =
    (sessionResult, effect) => {
      console.log(sessionResult);

      const { faceScan, status } = sessionResult;

      if (
        status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully
      ) {
        const friendlyStatus =
          FaceTecSDK.getFriendlyDescriptionForFaceTecSessionStatus(status);
        const error = new Error(
          `Session was not completed successfully, cancelling.  \
          Status: ${friendlyStatus} (${status})`
        );
        handleError(error);
        effect.cancel();
        return;
      }

      if (!faceScan) {
        throw new Error("Expected face scan to be present");
      }

      handleLivenessData({
        faceScan,
        auditTrailImage: sessionResult.auditTrail[0] || "",
        lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0] || "",
      }).then(
        (effectToTrigger) => {
          switch (effectToTrigger) {
            case "success":
              effect.succeed();
              break;
            case "retry":
              effect.retry();
              break;
            default:
              effect.cancel();
              throw new Error(
                "Unexpected effect returned from handleLivenessData"
              );
          }
        },
        (error) => {
          handleError(error);
          effect.cancel();
        }
      );
    };

  return {
    onFaceTecSDKCompletelyDone: onDone,
    processSessionResultWhileFaceTecSDKWaits: process,
  } as FaceTecFaceScanProcessor;
};

export default makeProcessor;
