import { AnyTaggedState, StateProps } from "../taggedState";
import ExpectContextState from "./ExpectContextState";

export type BoundExpectContextStateProps<
  ExpectedTag,
  State extends AnyTaggedState
> = {
  children: React.ComponentType<StateProps<State>[ExpectedTag & State["tag"]]>;
};

function createExpectContextState<
  ExpectedTag,
  Context extends React.Context<State>,
  State extends AnyTaggedState = React.ContextType<Context>
>(
  context: Context,
  tag: ExpectedTag
): React.FC<BoundExpectContextStateProps<ExpectedTag, State>> {
  const BoundExpectContextState: React.FC<
    BoundExpectContextStateProps<ExpectedTag, State>
  > = (props) => <ExpectContextState context={context} tag={tag} {...props} />;
  return BoundExpectContextState;
}

export default createExpectContextState;
