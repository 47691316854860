import "fontsource-roboto";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";
import { notifyUpdate } from "./components/AppUpdateContext";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// Register service worker to ensure we can hook the web requests.
serviceWorkerRegistration.register({
  onUpdate: notifyUpdate,
});

reportWebVitals(console.log);
